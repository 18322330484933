
export const TETROMINOS ={
    0: {shape: [[0]], cellImg : null},
    I: {
        shape :[
            [0, 'I', 0, 0],
            [0, 'I', 0, 0],
            [0, 'I', 0, 0],
            [0, 'I', 0, 0]
        ],
        basicCellImg : `img_srcs/game_img/tetris/basic/cell/tetrominoCellI.png`,
        basicTetrominoImg: `img_srcs/game_img/tetris/basic/tetromino/tetrominoI.png`,
        pastelCellImg : `img_srcs/game_img/tetris/pastel/cell/tetrominoCellI.png`,
        pastelTetrominoImg: `img_srcs/game_img/tetris/pastel/tetromino/tetrominoI.png`,
        doodleCellImg : `img_srcs/game_img/tetris/doodle/cell/tetrominoCell.png`,
        doodleTetrominoImg: `img_srcs/game_img/tetris/doodle/tetromino/tetrominoI.png`,
        jellyCellImg : `img_srcs/game_img/tetris/jelly/cell/tetrominoCellI.png`,
        jellyTetrominoImg: `img_srcs/game_img/tetris/jelly/tetromino/tetrominoI.png`,
        retroCellImg : `img_srcs/game_img/tetris/retro/cell/tetrominoCellI.png`,
        retroTetrominoImg: `img_srcs/game_img/tetris/retro/tetromino/tetrominoI.png`,
        legoCellImg : `img_srcs/game_img/tetris/lego/cell/tetrominoCellI.png`,
        legoTetrominoImg: `img_srcs/game_img/tetris/lego/tetromino/tetrominoI.png`
    },
    J: {
        shape :[
            [0, 'J', 0],
            [0, 'J', 0],
            ['J', 'J', 0]
        ],
        basicCellImg : `img_srcs/game_img/tetris/basic/cell/tetrominoCellJ.png`,
        basicTetrominoImg: `img_srcs/game_img/tetris/basic/tetromino/tetrominoJ.png`,
        pastelCellImg : `img_srcs/game_img/tetris/pastel/cell/tetrominoCellJ.png`,
        pastelTetrominoImg: `img_srcs/game_img/tetris/pastel/tetromino/tetrominoJ.png`,
        doodleCellImg : `img_srcs/game_img/tetris/doodle/cell/tetrominoCell.png`,
        doodleTetrominoImg: `img_srcs/game_img/tetris/doodle/tetromino/tetrominoJ.png`,
        jellyCellImg : `img_srcs/game_img/tetris/jelly/cell/tetrominoCellJ.png`,
        jellyTetrominoImg: `img_srcs/game_img/tetris/jelly/tetromino/tetrominoJ.png`,
        retroCellImg : `img_srcs/game_img/tetris/retro/cell/tetrominoCellJ.png`,
        retroTetrominoImg: `img_srcs/game_img/tetris/retro/tetromino/tetrominoJ.png`,
        legoCellImg : `img_srcs/game_img/tetris/lego/cell/tetrominoCellJ.png`,
        legoTetrominoImg: `img_srcs/game_img/tetris/lego/tetromino/tetrominoJ.png`
    },
    L: {
        shape :[
            [0, 'L', 0],
            [0, 'L', 0],
            [0, 'L', 'L']
        ],
        basicCellImg : `img_srcs/game_img/tetris/basic/cell/tetrominoCellL.png`,
        basicTetrominoImg: `img_srcs/game_img/tetris/basic/tetromino/tetrominoL.png`,
        pastelCellImg : `img_srcs/game_img/tetris/pastel/cell/tetrominoCellL.png`,
        pastelTetrominoImg: `img_srcs/game_img/tetris/pastel/tetromino/tetrominoL.png`,
        doodleCellImg : `img_srcs/game_img/tetris/doodle/cell/tetrominoCell.png`,
        doodleTetrominoImg: `img_srcs/game_img/tetris/doodle/tetromino/tetrominoL.png`,
        jellyCellImg : `img_srcs/game_img/tetris/jelly/cell/tetrominoCellL.png`,
        jellyTetrominoImg: `img_srcs/game_img/tetris/jelly/tetromino/tetrominoL.png`,
        retroCellImg : `img_srcs/game_img/tetris/retro/cell/tetrominoCellL.png`,
        retroTetrominoImg: `img_srcs/game_img/tetris/retro/tetromino/tetrominoL.png`,
        legoCellImg : `img_srcs/game_img/tetris/lego/cell/tetrominoCellL.png`,
        legoTetrominoImg: `img_srcs/game_img/tetris/lego/tetromino/tetrominoL.png`

    },
    O: {
        shape :[
            ['O', 'O'],
            ['O', 'O'],
        ],
        basicCellImg : `img_srcs/game_img/tetris/basic/cell/tetrominoCellO.png`,
        basicTetrominoImg: `img_srcs/game_img/tetris/basic/tetromino/tetrominoO.png`,
        pastelCellImg : `img_srcs/game_img/tetris/pastel/cell/tetrominoCellO.png`,
        pastelTetrominoImg: `img_srcs/game_img/tetris/pastel/tetromino/tetrominoO.png`,
        doodleCellImg : `img_srcs/game_img/tetris/doodle/cell/tetrominoCell.png`,
        doodleTetrominoImg: `img_srcs/game_img/tetris/doodle/tetromino/tetrominoO.png`,
        jellyCellImg : `img_srcs/game_img/tetris/jelly/cell/tetrominoCellO.png`,
        jellyTetrominoImg: `img_srcs/game_img/tetris/jelly/tetromino/tetrominoO.png`,
        retroCellImg : `img_srcs/game_img/tetris/retro/cell/tetrominoCellO.png`,
        retroTetrominoImg: `img_srcs/game_img/tetris/retro/tetromino/tetrominoO.png`,
        legoCellImg : `img_srcs/game_img/tetris/lego/cell/tetrominoCellO.png`,
        legoTetrominoImg: `img_srcs/game_img/tetris/lego/tetromino/tetrominoO.png`

    },
    S: {
        shape :[
            [0, 'S', 'S'],
            ['S', 'S', 0],
            [0, 0, 0]
        ],
        basicCellImg : `img_srcs/game_img/tetris/basic/cell/tetrominoCellS.png`,
        basicTetrominoImg: `img_srcs/game_img/tetris/basic/tetromino/tetrominoS.png`,
        pastelCellImg : `img_srcs/game_img/tetris/pastel/cell/tetrominoCellS.png`,
        pastelTetrominoImg: `img_srcs/game_img/tetris/pastel/tetromino/tetrominoS.png`,
        doodleCellImg : `img_srcs/game_img/tetris/doodle/cell/tetrominoCell.png`,
        doodleTetrominoImg: `img_srcs/game_img/tetris/doodle/tetromino/tetrominoS.png`,
        jellyCellImg : `img_srcs/game_img/tetris/jelly/cell/tetrominoCellS.png`,
        jellyTetrominoImg: `img_srcs/game_img/tetris/jelly/tetromino/tetrominoS.png`,
        retroCellImg : `img_srcs/game_img/tetris/retro/cell/tetrominoCellS.png`,
        retroTetrominoImg: `img_srcs/game_img/tetris/retro/tetromino/tetrominoS.png`,
        legoCellImg : `img_srcs/game_img/tetris/lego/cell/tetrominoCellS.png`,
        legoTetrominoImg: `img_srcs/game_img/tetris/lego/tetromino/tetrominoS.png`
    },
    T: {
        shape :[
            [0, 0, 0],
            ['T', 'T', 'T'],
            [0, 'T', 0]
        ],
        basicCellImg : `img_srcs/game_img/tetris/basic/cell/tetrominoCellT.png`,
        basicTetrominoImg: `img_srcs/game_img/tetris/basic/tetromino/tetrominoT.png`,
        pastelCellImg : `img_srcs/game_img/tetris/pastel/cell/tetrominoCellT.png`,
        pastelTetrominoImg: `img_srcs/game_img/tetris/pastel/tetromino/tetrominoT.png`,
        doodleCellImg : `img_srcs/game_img/tetris/doodle/cell/tetrominoCell.png`,
        doodleTetrominoImg: `img_srcs/game_img/tetris/doodle/tetromino/tetrominoT.png`,
        jellyCellImg : `img_srcs/game_img/tetris/jelly/cell/tetrominoCellT.png`,
        jellyTetrominoImg: `img_srcs/game_img/tetris/jelly/tetromino/tetrominoT.png`,
        retroCellImg : `img_srcs/game_img/tetris/retro/cell/tetrominoCellT.png`,
        retroTetrominoImg: `img_srcs/game_img/tetris/retro/tetromino/tetrominoT.png`,
        legoCellImg : `img_srcs/game_img/tetris/lego/cell/tetrominoCellT.png`,
        legoTetrominoImg: `img_srcs/game_img/tetris/lego/tetromino/tetrominoT.png`
    },
    Z: {
        shape :[
            ['Z', 'Z', 0],
            [0, 'Z', 'Z'],
            [0, 0, 0]
        ],
        basicCellImg : `img_srcs/game_img/tetris/basic/cell/tetrominoCellZ.png`,
        basicTetrominoImg: `img_srcs/game_img/tetris/basic/tetromino/tetrominoZ.png`,
        pastelCellImg : `img_srcs/game_img/tetris/pastel/cell/tetrominoCellZ.png`,
        pastelTetrominoImg: `img_srcs/game_img/tetris/pastel/tetromino/tetrominoZ.png`,
        doodleCellImg : `img_srcs/game_img/tetris/doodle/cell/tetrominoCell.png`,
        doodleTetrominoImg: `img_srcs/game_img/tetris/doodle/tetromino/tetrominoZ.png`,
        jellyCellImg : `img_srcs/game_img/tetris/jelly/cell/tetrominoCellZ.png`,
        jellyTetrominoImg: `img_srcs/game_img/tetris/jelly/tetromino/tetrominoZ.png`,
        retroCellImg : `img_srcs/game_img/tetris/retro/cell/tetrominoCellZ.png`,
        retroTetrominoImg: `img_srcs/game_img/tetris/retro/tetromino/tetrominoZ.png`,
        legoCellImg : `img_srcs/game_img/tetris/lego/cell/tetrominoCellZ.png`,
        legoTetrominoImg: `img_srcs/game_img/tetris/lego/tetromino/tetrominoZ.png`
    }
}

export const randomTetromino =()=>{
    const tetrominos ='IJLOSTZ'
    const randTetromino =tetrominos[Math.floor(Math.random()*tetrominos.length)]

    return TETROMINOS[randTetromino]
}