// ===== import base =====
import React from "react"
import styled from "styled-components"

// ===== import style =====
import { Input } from "../../styles/Input"
import { useGetData } from "../../hooks/useFetch"



// ===== component =====
const UnivList = (props) =>{
    // ===== state =====
    const univData = useGetData('/university/all')
    const [timer, setTimer] = React.useState(0)
    const univIdx = props.univIdx
    const setUnivIdx = props.setUnivIdx
    const setPostData = props.setPostData
    // 대학정보 불러오기 get
    // console.log(univData)

    const changeEvent = () =>{
        if(timer){
            clearTimeout(timer)
        }
        const newTimer = setTimeout( async() => {
            const univ_name = (document.getElementById("univ").value)
            // console.log(document.getElementById("univ").label)
            const idx = univData.filter( data => {
                return data.university_name === univ_name
            })
            setPostData( prevState => ({...prevState, universityIdx: idx.length >= 1 && idx[0].university_idx}))
            // console.log(idx, univIdx)
        }, 300)
        setTimer(newTimer)
    }

    return(
        <React.Fragment>
            <Input width="100%" max_width="289px" height="28px" placeholder="대학교" font_size="xxs" padding="0 10px" margin="0 10px 0 0"
            list="univ_list" id="univ" onChange={changeEvent} readOnly={props.readonly}/>
            <datalist id="univ_list">
                {
                    univData && univData.map( data =>{
                        return <option  value={data.university_name}></option>
                    })
                }
            </datalist>
        </React.Fragment>
    )
}

export default UnivList
